import { ca } from '@cian/analytics';

export const trackBlackFridayClick = () => {
  ca('eventSite', {
    action: 'click',
    category: 'BlackFriday',
    label: 'nb_main',
    name: 'oldevent',
  });
};
