import { ca } from '@cian/analytics';

export const trackBlackFridayShow = () => {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'BlackFriday',
    label: 'nb_main',
    name: 'oldevent',
  });
};
