import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { openPromoDetails } from '../../actions/newbuildingPromo';
import { BlackFridayPromoItem, NewbuildingPromoBlock } from '../../components/NewbuildingPromoBlock';
import {
  selectNewbuildingBlackFridayActive,
  selectNewbuildingBlackFridayMainPageImage,
  selectNewbuildingBlackFridayUrl,
} from '../../selectors/newbuildingBlackFriday';
import { selectPromos } from '../../selectors/newbuildingPromo';
import { INewbuildingPromo } from '../../types/newbuildingPromo';
import { useApplicationContext } from '../../utils/applicationContext';
import { getNewbuildingPromosUrl } from './helpers/getNewbuildingPromosUrl';
import {
  trackBlackFridayClick,
  trackBlackFridayShow,
  trackDetailsClick,
  trackPromoShow,
  trackToPromoClick,
} from './tracking';

export const NewbuildingPromoBlockContainer: React.VFC = () => {
  const { config } = useApplicationContext();
  const promosUrl = getNewbuildingPromosUrl(config);
  const promos = useSelector(selectPromos);

  const isBlackFridayActive = useSelector(selectNewbuildingBlackFridayActive);
  const blackFridayMainPageImage = useSelector(selectNewbuildingBlackFridayMainPageImage);
  const blackFridayUrl = useSelector(selectNewbuildingBlackFridayUrl);

  const dispatch = useDispatch();

  const handlePromoShow = React.useCallback(() => {
    trackPromoShow(promos);

    if (isBlackFridayActive) {
      trackBlackFridayShow();
    }
  }, [promos, isBlackFridayActive]);

  const handlePromoClick = React.useCallback(
    (promo: INewbuildingPromo, index: number) => {
      dispatch(openPromoDetails(promo));
      trackDetailsClick({ promo, index });
    },
    [dispatch],
  );

  // TODO: Удалить второе условие в CD-212817.
  if (!promos.length && !isBlackFridayActive) {
    return null;
  }

  return (
    <ActionAfterViewObserver callback={handlePromoShow} triggerOnce>
      <NewbuildingPromoBlock
        // TODO: Удалить Чёрную пятницу в CD-212817.
        blackFridayPromo={
          isBlackFridayActive ? (
            <BlackFridayPromoItem
              image={blackFridayMainPageImage}
              url={blackFridayUrl}
              onClick={trackBlackFridayClick}
            />
          ) : null
        }
        promos={promos}
        onPromoClick={handlePromoClick}
        onPromoLinkClick={trackToPromoClick}
        promosUrl={promosUrl}
      />
    </ActionAfterViewObserver>
  );
};
