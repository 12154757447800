import * as React from 'react';

import { FILTERS } from '../../constants/filters';
import { FDealType, FOfferType } from '../../utils/category';
import { FiltersSearchButtonsContainer } from '../../containers/FiltersSearchButtonsContainer';
import { getFiltersList } from './helpers';
import * as styles from './Filters.css';
import { FilterRegionContainer } from '../../containers/FilterRegionContainer';
import { FiltersMortgageButtonsContainer } from '../../containers/FiltersMortgageButtonsContainer';
import { FilterMortgageBuildingTypeContainer } from '../../containers/FilterMortgageBuildingTypeContainer';
import { FilterMortgageTermContainer } from '../../containers/FilterMortgageTermContainer';
import { FilterMortgageRealtyPriceContainer } from '../../containers/FilterMortgageRealtyPriceContainer';
import { FilterMortgageDownPaymentContainer } from '../../containers/FilterMortgageDownPaymentContainer';
import { ESpecialTabs } from '../../constants/specialTabs';
import { IFiltersProps } from './types';

const FilterItems: React.FC<IFiltersProps> = ({ dealType, offerType, specialTabActive }) => {
  if (specialTabActive === ESpecialTabs.Mortgage) {
    return (
      <>
        <FilterMortgageBuildingTypeContainer className={styles['mortgage_building_type']} />
        <FilterMortgageRealtyPriceContainer className={styles['mortgage_realty_price']} />
        <FilterMortgageDownPaymentContainer className={styles['mortgage_down_payment']} />
        <FilterMortgageTermContainer className={styles['mortgage_term']} />
        <FilterRegionContainer className={styles['mortgage_region']} />
      </>
    );
  }

  const filters = getFiltersList({ dealType, offerType });

  return (
    <>
      {filters.map(filterName => {
        const filter = FILTERS[filterName];
        const { component: Component } = filter;

        return <Component key={`filter_${filterName}`} dealType={dealType} offerType={offerType} />;
      })}
    </>
  );
};

const FilterButtons = ({ specialTabActive }: { specialTabActive: ESpecialTabs | null }) => {
  if (specialTabActive === ESpecialTabs.Mortgage) {
    return <FiltersMortgageButtonsContainer />;
  }

  return <FiltersSearchButtonsContainer showFindOnMapButton />;
};

export interface IFilterProps {
  dealType: FDealType;
  offerType: FOfferType;
}

export function Filters({ dealType, offerType, specialTabActive }: IFiltersProps) {
  return (
    <>
      <div className={styles['container']} data-mark="Filters">
        <div className={styles['wrapper']}>
          <FilterItems dealType={dealType} offerType={offerType} specialTabActive={specialTabActive} />
        </div>
      </div>
      <div className={styles['buttons']}>
        <FilterButtons specialTabActive={specialTabActive} />
      </div>
    </>
  );
}
