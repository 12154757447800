import * as React from 'react';
import { UIHeading5, UIText2 } from '@cian/ui-kit';

import { ENTER_KEY } from '../../../../constants/keyboard';
import * as styles from './BlackFridayPromoItem.css';

interface IBlackFridayPromoItemProps {
  image: string;
  url: string | undefined;
  onClick: () => void;
}

export const BlackFridayPromoItem: React.FC<IBlackFridayPromoItemProps> = ({ image, url, onClick }) => {
  const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();
      event.currentTarget.click();
    }
  }, []);

  return (
    <a
      className={styles['container']}
      style={{ backgroundImage: `url(${image})` }}
      onKeyDown={handleKeyDown}
      onClick={onClick}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      tabIndex={0}
      data-testid="BlackFridayPromoItem"
    >
      <div className={styles['subtitle']}>
        <UIText2 color="current_color">Эксклюзив</UIText2>
      </div>
      <div className={styles['name']}>
        <UIHeading5 as="h3" color="white_100">
          Скидки на новостройки переехали к нам
        </UIHeading5>
      </div>
      <UIText2 color="white_100">До 29.09.2024</UIText2>
    </a>
  );
};
