import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { getMeta, persistFilters } from '../../actions/filters';
import { FiltersSearchButtons } from '../../components/FiltersSearchButtons';
import { formatOfferCount } from './utils/formatOfferCount';
import { selectPageKind } from '../../selectors/pageKind';
import { selectDisableRegionExpansion } from '../../selectors/regionExpansion';
import { EPageKind } from '../../types/page';
import { IGeocodeState, IMetaState } from '../../types/filters';

interface IFiltersSearchButtonsContainerStateProps {
  pageKind: EPageKind;
  meta: IMetaState;
  geocode: IGeocodeState;
  offerCount: string;
  disableRegionExpansion: boolean;
}

interface IFiltersSearchButtonsContainerDispatchProps {
  getMeta(): void;
  persistFilters(): void;
}

export function mapStateToProps(state: IApplicationState): IFiltersSearchButtonsContainerStateProps {
  const {
    filters: { meta },
  } = state;

  const pageKind = selectPageKind(state);
  const disableRegionExpansion = selectDisableRegionExpansion(state);

  const offerCount = formatOfferCount({ offerCount: meta.count });

  return {
    pageKind,
    meta,
    geocode: state.filters.geocode,
    offerCount,
    disableRegionExpansion,
  };
}

export const mapDispatchToProps: IFiltersSearchButtonsContainerDispatchProps = {
  getMeta,
  persistFilters,
};

export const FiltersSearchButtonsContainer = connect(mapStateToProps, mapDispatchToProps)(FiltersSearchButtons);
