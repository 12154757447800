/* eslint-disable no-case-declarations */
import * as React from 'react';

import { EPageKind } from '../../types/page';
import { EMetaStatus, IMetaState, IGeocodeState, EGeocodeStatus } from '../../types/filters';
import { FiltersSearchButton } from '../FiltersSearchButton';
import * as tracking from './tracking';
import * as styles from './FiltersSearchButtons.css';
import { setCookie } from '@cian/utils/lib/browser/cookie';
import { EXPANSION_REGION_DISABLE_COOKIE } from '../../constants/region';
import { getCookie } from '../../utils/helpers/cookie_helper';

/**
 * @interface
 */
export interface IFiltersSearchButtonsProps {
  pageKind: EPageKind;
  meta: IMetaState;
  geocode: IGeocodeState;
  offerCount: string;
  disableRegionExpansion: boolean;
  /**
   * @field
   * @default true
   * @description Параметр отвечает за отображение кнопки "Показать на карте"
   */
  showFindOnMapButton?: boolean;
  getMeta(): void;
  persistFilters(): void;
}

export enum EFilterSearchButtonsStatus {
  ListLoading = 'listLoading',
  MapLoading = 'mapLoading',
}

export interface IFiltersSearchButtonsState {
  status: EFilterSearchButtonsStatus | null;
}

export class FiltersSearchButtons extends React.Component<IFiltersSearchButtonsProps, IFiltersSearchButtonsState> {
  public static readonly defaultProps: Partial<IFiltersSearchButtonsProps> = {
    showFindOnMapButton: true,
  };

  public state: IFiltersSearchButtonsState = {
    status: null,
  };

  public componentDidMount() {
    window.addEventListener('pageshow', this.handleLoadPage);
  }

  public componentDidUpdate(prevProps: IFiltersSearchButtonsProps) {
    if (this.state.status !== null) {
      if (
        [EGeocodeStatus.Initial, EGeocodeStatus.Succeed].includes(this.props.geocode.status) &&
        prevProps.meta.status !== this.props.meta.status &&
        this.props.meta.status !== EMetaStatus.Loading
      ) {
        switch (this.props.meta.status) {
          case EMetaStatus.Succeed:
            const isMap = this.state.status === EFilterSearchButtonsStatus.MapLoading;
            const url = isMap ? this.props.meta.mapUrl : this.props.meta.url;
            const trackEvent = isMap ? tracking.trackSearchOnMapButtonClick : tracking.trackSearchButtonClick;

            window.location.href = url;
            trackEvent(this.props.pageKind, url);
            break;

          case EMetaStatus.Failed:
            this.setState({
              status: null,
            });
            alert('Не удалось получить информацию по вашему запросу. Попробуйте ещё раз.');
            break;
        }
      }

      if (
        prevProps.geocode.status !== this.props.geocode.status &&
        this.props.geocode.status === EGeocodeStatus.Failed
      ) {
        this.setState({
          status: null,
        });
      }
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('pageshow', this.handleLoadPage);
  }

  public render() {
    const {
      meta: { url, mapUrl },
      showFindOnMapButton,
      offerCount,
    } = this.props;
    const { status } = this.state;

    return (
      <span className={styles['container']}>
        {showFindOnMapButton && (
          <FiltersSearchButton
            dataMark="FiltersSearchOnMapButton"
            text="Показать на карте"
            title={offerCount}
            href={mapUrl}
            onClick={this.handleMapSearchButtonClick}
            isLoading={status === EFilterSearchButtonsStatus.MapLoading}
            isLight
          />
        )}

        <FiltersSearchButton
          dataMark="FiltersSearchButton"
          text="Найти"
          title={offerCount}
          href={url}
          onClick={this.handleListSearchButtonClick}
          isLoading={status === EFilterSearchButtonsStatus.ListLoading}
        />
      </span>
    );
  }

  private handleListSearchButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    this.props.persistFilters();
    this.handleSearchButtonClick(event);
  };

  private handleMapSearchButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    this.props.persistFilters();
    this.handleSearchButtonClick(event, true);
  };

  private handleSearchButtonClick = (event: React.MouseEvent<HTMLElement>, isMap: boolean = false) => {
    const {
      meta: { status: metaStatus, mapUrl, url },
      geocode: { status: geocodeStatus },
      pageKind,
      disableRegionExpansion,
    } = this.props;

    const resultUrl = isMap ? mapUrl : url;
    const trackEvent = isMap ? tracking.trackSearchOnMapButtonClick : tracking.trackSearchButtonClick;

    this.setState({
      status: isMap ? EFilterSearchButtonsStatus.MapLoading : EFilterSearchButtonsStatus.ListLoading,
    });

    if (geocodeStatus === EGeocodeStatus.Loading || metaStatus !== EMetaStatus.Succeed) {
      event.preventDefault();
    } else {
      trackEvent(pageKind, resultUrl);
    }

    if ([EGeocodeStatus.Initial, EGeocodeStatus.Succeed].includes(geocodeStatus) && metaStatus === EMetaStatus.Failed) {
      this.props.getMeta();
    }

    if (disableRegionExpansion && !getCookie(document.cookie, EXPANSION_REGION_DISABLE_COOKIE)) {
      setCookie(EXPANSION_REGION_DISABLE_COOKIE, '1');
    }
  };

  private handleLoadPage = (event: PageTransitionEvent) => {
    if (event.persisted) {
      this.setState({
        status: null,
      });
    }
  };
}
