import * as React from 'react';

import { INewbuildingPromo } from '../../types/newbuildingPromo';
import { ItemsCarousel } from '../ItemsCarousel';
import { PromoAttributes } from './components/PromoAttributes';
import { PromoItem } from './components/PromoItem';
import { PromoLink } from './components/PromoLink';
import * as styles from './NewbuildingPromoBlock.css';

export interface INewbuildingPromoBlockProps {
  blackFridayPromo: React.ReactNode;
  onPromoClick(promo: INewbuildingPromo, index: number): void;
  onPromoLinkClick?(): void;
  promos: INewbuildingPromo[];
  promosUrl: string;
}

export const NewbuildingPromoBlock: React.FC<INewbuildingPromoBlockProps> = ({
  blackFridayPromo,
  onPromoClick,
  onPromoLinkClick,
  promos,
  promosUrl,
}) => {
  return (
    <section className={styles['container']} data-testid="NewbuildingPromoBlock">
      <div className="cg-container-fluid-xs">
        <h2 className={styles['title']}>Акции и скидки на новостройки</h2>
        <ItemsCarousel scrollVisible>
          {blackFridayPromo}
          {promos.map((promo, idx) => {
            return (
              <PromoItem
                key={promo.uniquePromoId}
                attributes={<PromoAttributes promo={promo} />}
                activeDateTo={promo.activeDateToText}
                highlighted={promo.isHighlighted}
                name={promo.promoName}
                providerLogo={promo.providerLogo}
                providerName={promo.providerName}
                onClick={() => onPromoClick(promo, idx)}
              />
            );
          })}
          {!blackFridayPromo && <PromoLink url={promosUrl} onClick={onPromoLinkClick} />}
        </ItemsCarousel>
      </div>
    </section>
  );
};
