import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TThunkDispatch } from '../../types/redux';
import { selectDates } from '../../selectors/filters/dates';
import { useToggleState } from '../../hooks';
import { FilterDates } from '../../components/FilterDates';
import { addDays, formatISO, parseISO, startOfToday } from 'date-fns';
import { getLabel } from './utils';
import { setDates } from '../../actions/filters/dates';
import { getMeta } from '../../actions/filters';

export const FilterDatesContainer = () => {
  const [isOpen, toggleIsOpen] = useToggleState();

  const dates = useSelector(selectDates);

  const today = startOfToday();
  const minDate = React.useMemo(() => today, [today]);
  const maxDate = React.useMemo(() => addDays(today, 365), [today]);
  const fromValue = dates?.gte ? parseISO(dates.gte) : null;
  const toValue = dates?.lt ? parseISO(dates.lt) : null;
  const label = React.useMemo(() => getLabel(fromValue, toValue), [fromValue, toValue]);
  const isEmpty = !fromValue || !toValue;

  const dispatch = useDispatch<TThunkDispatch>();

  const handleDatesChange = React.useCallback(
    (from: Date | undefined, to: Date | undefined) => {
      let fromValue = from;
      let toValue = to;

      if (fromValue && !toValue) {
        toValue = addDays(fromValue, 1);
      }
      if (toValue && !fromValue) {
        fromValue = addDays(toValue, -1);
      }
      if (fromValue && toValue && fromValue >= toValue) {
        toValue = addDays(fromValue, 1);
      }

      if (fromValue && toValue) {
        const gte = formatISO(fromValue, { representation: 'date' });
        const lt = formatISO(toValue, { representation: 'date' });

        dispatch(setDates({ gte, lt }));
        dispatch(getMeta());

        toggleIsOpen();
      }
    },
    [dispatch, toggleIsOpen],
  );

  const handleButtonClick = React.useCallback(() => {
    toggleIsOpen();
  }, [isOpen, toggleIsOpen]);

  return (
    <FilterDates
      opened={isOpen}
      label={label}
      min={minDate}
      max={maxDate}
      fromValue={fromValue}
      toValue={toValue}
      empty={isEmpty}
      onClick={handleButtonClick}
      onChange={handleDatesChange}
    />
  );
};
